import React, { Component } from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { BrowserRouter as Router, Switch, Route, Link, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";

import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

import * as axiosAuthHelper from "../../helper/axiosHeader/axiosAuthHelper";
import * as jwtUserTokenHelper from "../../helper/localStorage/jwtUserTokenHelper";
import * as refreshTokenHelper from "../../helper/localStorage/refreshTokenHelper";

import * as accountService from "../../services/account/accountService";
import * as onlineService from "../../services/online/onlineService";
import * as currentUserHelper from "../../helper/localStorage/currentUserHelper";
//import videoAuthService from '../../services/cc-video/auth-service';

import {
    startLoadingAction,
    finishLoadingAction
} from "../../redux/actions/loadingActions";

import LoadingPage from "../layout/loadingPage";
import convertUrltoEncode from "../../helper/validation/convertUrltoEncode";



class NavbarMenu extends Component {
    constructor(props) {
        super(props);
        //props.handleFunc_toggleNavbarMenu

        this.state = {
            userLogo: "-",
            userName: "-",
            uId: 0,
            uName: "",
            userCredit: "-",
            isOpen: this.props.isOpen,
            isOnline: false
        };
    }



    // load ajax and get data from server
    async componentDidMount() {

        const _currentUser = currentUserHelper.getCurrentUserFromLocalStorage_andDoAllUserSettings();
        if (_currentUser === null) {
            this.props.history.push("/login");
        }
        else {
            this.setState({
                userLogo: baseUrlAddress.getFilesBaseUrl() + _currentUser.Avatar,
                displayName: _currentUser.FirstName + " " + _currentUser.LastName,
                userName: _currentUser.UserName,
                userCredit: _currentUser.UserPriceCredit,
                isOnline: false,
                uId: _currentUser.UserId,
                uName: _currentUser.UserName
            });

            const _check = await onlineService.checkOnline();
            if (_check.success === true)
                this.setState({ isOnline: _check.data.isOnline });

        }

    }


    onLogoutHandle = async (e) => {
        e.preventDefault();

        this.props.startLoadingAction();

        const _off = await onlineService.setOffline();
        if (_off.success) {
            this.setState({ isOnline: _off.data.isOnline });
            //toast.success("Set Offline Successful");
        }

        let userData = {
            refreshToken: refreshTokenHelper.getTokenFromLocalStorage()
        };
        const _result = await accountService.userLogout(userData);
        if (_result.success) {

            //videoAuthService.logout();

            //toast.success("Logout Successful");
        }

        this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)

        refreshTokenHelper.removeTokenFromLocalStorage();
        jwtUserTokenHelper.removeTokenFromLocalStorage();
        axiosAuthHelper.removeAuthTokenFromAxiosHeader();

        this.props.finishLoadingAction();

        this.props.history.push("/");

    };


    doOffline = async (e) => {
        e.preventDefault();

        const _off = await onlineService.setOffline();
        if (_off.success) {
            this.setState({ isOnline: _off.data.isOnline });
            //toast.success("Set Offline Successful");
        }
    };

    doOnline = async (e) => {
        e.preventDefault();

        const _on = await onlineService.setOnline();
        if (_on.success) {
            this.setState({ isOnline: _on.data.isOnline });
            //toast.success("Set Online Successful");
        }

    };


    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :
        //userLogo: "-"
        //userName: "-"
        //userCredit: "-"
        //isOpen: this.props.isOpen
        //isOnline: false


        // Redux :
        // this.props.accountState.currentUser
        if (this.props.accountState.currentUser) {
            this.state = {
                userLogo: baseUrlAddress.getFilesBaseUrl() + this.props.accountState.currentUser.Avatar,
                userName: this.props.accountState.currentUser.FirstName + " " + this.props.accountState.currentUser.LastName,
                userCredit: this.props.accountState.currentUser.UserPriceCredit,
                isOpen: this.props.isOpen,
                isOnline: this.state.isOnline,
                uId: this.props.accountState.currentUser.UserId,
                uName: this.props.accountState.currentUser.UserName
            };
        }

        let userLogoStyle = { backgroundImage: "url(" + convertUrltoEncode(this.state.userLogo) + ")" };

        return (

            <Sidebar
                onBackdropClick={() => this.props.handleFunc_toggleNavbarMenu(false)}
                toggled={this.state.isOpen}
                breakPoint="always"
                rtl
            >


                {/* -------------- NavbarMenu -------------------------------------------------- */}
                <nav id="menu">
                    <div className="sidebar-container">
                        <div className="info-side-box">
                            <div className="row p-0 m-0">
                                <div className="col-5 d-flex align-items-center justify-content-center">
                                    <div className="avatar-box" style={userLogoStyle}></div>
                                </div>
                                <div className="col-7 d-flex align-items-center justify-content-center">
                                    <div className="user-name">
                                        <p style={{paddingBottom: "0px", marginBottom: "0px"}}>
                                            {this.state.userName}
                                        </p>
                                        <p style={{paddingTop: "0px", marginTop: "0px"}}>
                                            {this.state.displayName}
                                        </p>
                                        <p>
                                        <span>
                                            وضعیت :
                                        </span>
                                            <span className="money">
                                            {(this.state.isOnline)
                                                ?
                                                (
                                                    <span style={{color: "blue", fontWeight: "bold"}}> آنلاین </span>
                                                )
                                                :
                                                (
                                                    <span style={{color: "red", fontWeight: "bold"}}> آفلاین </span>
                                                )
                                            }
                                        </span>
                                        </p>

                                    </div>
                                </div>
                                <div className="col-12">
                                    {(this.state.isOnline)
                                        ?
                                        (
                                            <button className="btn btn-stock" onClick={(e) => this.doOffline(e)}>
                                                <i className="fas fa-check"></i>
                                                <span style={{color: "red"}}>آفلاین شو</span>
                                            </button>
                                        )
                                        :
                                        (
                                            <button className="btn btn-stock" onClick={(e) => this.doOnline(e)}>
                                                <i className="fas fa-check"></i>
                                                <span style={{color: "green"}}>آنلاین شو</span>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="nav nav-profile flex-column nav-pills bg-detail" aria-orientation="vertical">

                            {
                                (this.props.menuState.pageUrl === "Dashboard")
                                    ?
                                    (
                                        <Link to={"/"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-home"></i>خانه</Link>
                                    )
                                    :
                                    (
                                        <Link to={"/"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-home"></i>خانه</Link>
                                    )
                            }
                            {
                                (this.props.menuState.pageUrl === "MeetingsReserveArchive")
                                    ?
                                    (
                                        <Link to={"/meetings-reserve-archive"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-list-ul"></i>لیست جلسات</Link>
                                    )
                                    :
                                    (
                                        <Link to={"/meetings-reserve-archive"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-list-ul"></i>لیست جلسات</Link>
                                    )
                            }

                            {/*{*/}
                            {/*    (this.props.menuState.pageUrl === "MeetingsArchive")*/}
                            {/*        ?*/}
                            {/*        (*/}
                            {/*            <Link to={"/meetings-archive"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-archive"></i>آرشیو جلسات</Link>*/}
                            {/*        )*/}
                            {/*        :*/}
                            {/*        (*/}
                            {/*            <Link to={"/meetings-archive"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-archive"></i>آرشیو جلسات</Link>*/}
                            {/*        )*/}
                            {/*}*/}


                            {
                                (this.props.menuState.pageUrl === "Transaction")
                                    ?
                                    (
                                        <Link to={"/transaction"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-money-check-alt"></i>امور مالی</Link>
                                    )
                                    :
                                    (
                                        <Link to={"/transaction"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-money-check-alt"></i>امور مالی</Link>
                                    )
                            }


                            {/*{*/}
                            {/*    (this.props.menuState.pageUrl === "Blog")*/}
                            {/*        ?*/}
                            {/*        (*/}
                            {/*            <Link to={"/blog"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-book-reader"></i>مجله روانشناسی</Link>*/}
                            {/*        )*/}
                            {/*        :*/}
                            {/*        (*/}
                            {/*            <Link to={"/blog"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-book-reader"></i>مجله*/}
                            {/*                روانشناسی</Link>*/}
                            {/*        )*/}
                            {/*}*/}
                            {/*{
                            (this.props.menuState.pageUrl === "BlogCreate")
                                ?
                                (
                                    <Link to={"/add-blog"} className="nav-link active"  onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-money-check-alt"></i>ایجاد مقاله جدید</Link>
                                )
                                :
                                (
                                    <Link to={"/add-blog"} className="nav-link"  onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-money-check-alt"></i>ایجاد مقاله جدید</Link>
                                )
                        }*/}

                            {/*<a rel="noreferrer"*/}
                            {/*   href={baseUrlAddress.getPortalBaseUrl() + "/Admin/SupplyArticles/Create/?i=" + this.state.uId + "&n=" + this.state.uName}*/}
                            {/*   className="nav-link"><i className="fas fa-pen"></i>ایجاد مقاله جدید</a>*/}

                            {/*{*/}
                            {/*    (this.props.menuState.pageUrl === "Faq")*/}
                            {/*        ?*/}
                            {/*        (*/}
                            {/*            <Link to={"/faq"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-question"></i>سوالات متداول</Link>*/}
                            {/*        )*/}
                            {/*        :*/}
                            {/*        (*/}
                            {/*            <Link to={"/faq"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-question"></i>سوالات*/}
                            {/*                متداول</Link>*/}
                            {/*        )*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    (this.props.menuState.pageUrl === "AboutUs")*/}
                            {/*        ?*/}
                            {/*        (*/}
                            {/*            <Link to={"/about-us"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-info"></i>درباره یاری‌وی</Link>*/}
                            {/*        )*/}
                            {/*        :*/}
                            {/*        (*/}
                            {/*            <Link to={"/about-us"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-info"></i>درباره*/}
                            {/*                یاری‌وی</Link>*/}
                            {/*        )*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    (this.props.menuState.pageUrl === "ContactUs")*/}
                            {/*        ?*/}
                            {/*        (*/}
                            {/*            <Link to={"/contact-us"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-headset"></i>ارتباط با پشتیبانی</Link>*/}
                            {/*        )*/}
                            {/*        :*/}
                            {/*        (*/}
                            {/*            <Link to={"/contact-us"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-headset"></i>ارتباط*/}
                            {/*                با پشتیبانی</Link>*/}
                            {/*        )*/}
                            {/*}*/}


                            {
                                (this.props.menuState.pageUrl === "MeetingsLostedReserveArchive")
                                    ?
                                    (
                                        <Link to={"/meetings-losted-reserve-archive"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-exclamation-triangle"></i>لیست جلسات رزرو از دست
                                            داده</Link>
                                    )
                                    :
                                    (
                                        <Link to={"/meetings-losted-reserve-archive"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i
                                            className="fas fa-exclamation-triangle"></i>لیست جلسات رزرو از دست
                                            داده</Link>
                                    )
                            }
                            {/*{*/}
                            {/*    (this.props.menuState.pageUrl === "MeetingsRejectedReserveArchive")*/}
                            {/*        ?*/}
                            {/*        (*/}
                            {/*            <Link to={"/meetings-rejected-reserve-archive"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-times"></i>لیست جلسات رزرو رد شده</Link>*/}
                            {/*        )*/}
                            {/*        :*/}
                            {/*        (*/}
                            {/*            <Link to={"/meetings-rejected-reserve-archive"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i*/}
                            {/*                className="fas fa-times"></i>لیست جلسات رزرو رد شده</Link>*/}
                            {/*        )*/}
                            {/*}*/}


                            {
                                (this.props.menuState.pageUrl === "Setting")
                                    ?
                                    (
                                        <Link to={"/setting"} className="nav-link active" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-cog"></i>تنظیمات</Link>
                                    )
                                    :
                                    (
                                        <Link to={"/setting"} className="nav-link" onClick={() => this.props.handleFunc_toggleNavbarMenu(!this.state.isOpen)}><i className="fas fa-cog"></i>تنظیمات</Link>
                                    )
                            }

                            <Link className="nav-link" onClick={(e) => this.onLogoutHandle(e)}><i
                                className="fas fa-power-off"></i>خروج از حساب کاربری</Link>

                            {/* <Link to={"/pg3/5/10?name=alireza&family=kbh&age=31"} className="nav-link" onClick={() => this.onLogoutHandle()}>
                                <i className="fas fa-power-off"></i>
                                /pg3/5/10?name=alireza&family=kbh&age=31
                            </Link> */}

                        </div>
                    </div>
                </nav>
                {/* -------------- NavbarMenu -------------------------------------------------- */}


            </Sidebar>
        );
    }
}

// this.props.accountState.loading
// this.props.accountState.errorMessage
// this.props.accountState.isAuthenticated
// this.props.accountState.currentUser

// this.props.menuState.pageUrl
// this.props.menuState.pageTitle

const map_State_to_Props = state => ({
    accountState: state.accountReducer,
    menuState: state.menuReducer
});

// this.props.startLoadingAction()
// this.props.finishLoadingAction()
const map_DispatchAction_to_Props = {
    startLoadingAction,
    finishLoadingAction
};

export default withRouter(connect(map_State_to_Props, map_DispatchAction_to_Props)(NavbarMenu));
