import * as menuActionTypes from "../actionTypes/menuActionTypes";

const initialState = {
    pageUrl: "---",
    pageTitle: "---"
};

export default function menuReducer(state = initialState, action) {
    switch (action.type) {
        case menuActionTypes.Dashboard:
            return {
                ...state,
                pageUrl: "Dashboard",
                pageTitle: "خانه"
            };
        case menuActionTypes.AboutUs:
            return {
                ...state,
                pageUrl: "AboutUs",
                pageTitle: "درباره یاری‌وی"
            };
        case menuActionTypes.Blog:
            return {
                ...state,
                pageUrl: "Blog",
                pageTitle: "مجله روانشناسی"
            };
        case menuActionTypes.Categories:
            return {
                ...state,
                pageUrl: "Categories",
                pageTitle: "مشاوره جدید"
            };
        case menuActionTypes.ContactUs:
            return {
                ...state,
                pageUrl: "ContactUs",
                pageTitle: "ارتباط با پشتیبانی"
            };
        case menuActionTypes.Faq:
            return {
                ...state,
                pageUrl: "Faq",
                pageTitle: "سوالات متداول"
            };
        case menuActionTypes.MeetingsArchive:
            return {
                ...state,
                pageUrl: "MeetingsArchive",
                pageTitle: "آرشیو جلسات"
            };
        case menuActionTypes.MeetingsReserveArchive:
            return {
                ...state,
                pageUrl: "MeetingsReserveArchive",
                pageTitle: "لیست جلسات"
            };
        case menuActionTypes.MeetingsRejectedReserveArchive:
            return {
                ...state,
                pageUrl: "MeetingsRejectedReserveArchive",
                pageTitle: "لیست جلسات رزرو رد شده"
            };
        case menuActionTypes.MeetingsLostedReserveArchive:
            return {
                ...state,
                pageUrl: "MeetingsLostedReserveArchive",
                pageTitle: "لیست جلسات رزرو از دست داده"
            };
        case menuActionTypes.Profile:
            return {
                ...state,
                pageUrl: "Profile",
                pageTitle: "پروفایل"
            };
        case menuActionTypes.Setting:
            return {
                ...state,
                pageUrl: "Setting",
                pageTitle: "تنظیمات"
            };
        case menuActionTypes.Transaction:
            return {
                ...state,
                pageUrl: "Transaction",
                pageTitle: "امور مالی"
            };
        case menuActionTypes.Notification:
            return {
                ...state,
                pageUrl: "Notification",
                pageTitle: "اعلانات"
            };
        case menuActionTypes.NotFound:
            return {
                ...state,
                pageUrl: "NotFound",
                pageTitle: "صفحه ای پیدا نشد"
            };
        case menuActionTypes.BlogCreate:
            return {
                ...state,
                pageUrl: "BlogCreate",
                pageTitle: "ایجاد مقاله"
            };
        case menuActionTypes.FileRecord:
            return {
                ...state,
                pageUrl: "FileRecord",
                pageTitle: "پرونده کاربر"
            };
        case menuActionTypes.MessageChat:
            return {
                ...state,
                pageUrl: "MessageChat",
                pageTitle: "جلسه چت"
            };
        default:
            return state;
    }
}
