import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import * as baseUrlAddress from "../../utils/urlAddress/baseUrlAddress";
import * as notifService from "../../services/notification/notificationService";

class Notification extends Component {
    constructor(props) {
        super(props);

        // برای بار اولی که اپ بالا می آید
        this.state = {
            notifs: [],
            pageSize: 9,
            currentPage: 1
        };
    }

    async componentDidMount() {
        const reqData = { pageindex: 1 };
        const _obj = await notifService.getList(reqData);
        if (_obj.success === true) {
            if (_obj.data !== null) {
                this.setState({ notifs: _obj.data });
            }
        }

    }



    // render هر دفعه بعد از تغییر state هر دفعه دوباره فراخوانی میشود
    // متغیر های تعریف شده در render هر دفعه از اول مقدار دهی میشوند
    render() {

        // برای هر دفعه که تغییرات در 
        // render
        // نمیخوایم اعمال شود
        // مثلا وقتی که با
        // props
        // مقدار جدیدی را از کامپونت والد میخواهیم در
        // state
        // تغییر دهیم :

        return (
            <React.Fragment>

                <div className="card-body">
                    <ul>


                        {this.state.notifs.length === 0
                            ?
                            (
                                <>
                                    <div className="col-12 mx-auto">
                                        <br />
                                        <center > هیچ اعلانی وجود ندارد </center>
                                        <br />
                                    </div>
                                </>
                            )
                            :
                            (
                                <>

                                    { /* ----------- key={item._id} must be for list in react ---------------- */}
                                    {this.state.notifs.slice(0, 3).map(item => (

                                        <li key={item.id}>
                                            <div className="notification">
                                                {(item.isSeen === false) &&
                                                    <div className="dot red">new</div>
                                                }
                                                {(item.isSeen === true) &&
                                                    <div className="dot green">read</div>
                                                }
                                                <span className="title">
                                                    {item.title}
                                                    <br />
                                                    {item.description}
                                                </span>
                                                { /*<span className="date">{item.createDateTime.slice(0, 9)}</span>*/}
                                                <span>{item.createDateTime_Shamsi_InLocal}</span>
                                            </div>
                                        </li>
                                    ))}
                                    { /* ----------- key={item._id} must be for list in react ---------------- */}

                                </>
                            )
                        }
                        
                    </ul>

                </div>

            </React.Fragment>
        );
    }
}

export default Notification;
